<template>
  <div class="times-picker">
    <v-select
      v-model="inputTimesMode"
      :items="timesModes"
      :label="$t('__storemessagecronjob_timesmode')"
      outlined
      dense
    />
    <v-input :rules="[timesRequiredRule]">
      <table class="times-table">
        <v-list>
          <template v-for="(t, i) of inputTimes">
            <v-list-item :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="t"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="d-flex flex-row">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="setTime(i)" v-bind="attrs" v-on="on">
                      <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("__edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="deleteTime(i)" v-bind="attrs" v-on="on">
                      <v-icon color="grey lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("__delete") }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="`${i}-divider`" />
          </template>
          <v-list-item>
            <v-list-item-content>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text @click="addTime" v-bind="attrs" v-on="on">
                    <v-icon large color="grey lighten-1"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ $t("__new") }}</span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </table>
    </v-input>
    <v-dialog v-model="timeFormShow" persistent max-width="600px">
      <v-card>
        <div class="pa-4 pb-0">
          <v-form ref="timeForm" v-model="timeFormIsValid">
            <template v-if="timesMode === 'date-time'">
              <date-time-picker
                :date="inputTimeFormDateTime_Date"
                @update:date="inputTimeFormDateTime_Date = $event"
                :time="inputTimeFormDateTime_Time"
                @update:time="inputTimeFormDateTime_Time = $event"
                :date-rules="[requiredRule]"
                :time-rules="[requiredRule]"
                :min-date="startDate"
                :locale="$i18n.locale"
                outlined
                dense
                @time-invalid="timeIsValid"
              />
            </template>
            <template
              v-if="timesMode === 'weekday-time' || timesMode === 'day-time'"
            >
              <table width="100%" style="table-layout: fixed;">
                <tr>
                  <td width="30%">
                    <template v-if="timesMode === 'weekday-time'">
                      <v-select
                        v-model="inputTimeFormDayTime_Day"
                        :items="weekdays"
                        :label="$t('__day_of_week')"
                        outlined
                        dense
                      />
                    </template>
                    <template v-if="timesMode === 'day-time'">
                      <v-text-field
                        v-model="inputTimeFormDayTime_Day"
                        :label="$t('__day_of_month')"
                        :rules="[requiredRule, dayRule]"
                        type="number"
                        min="1"
                        max="31"
                        outlined
                        dense
                      />
                    </template>
                  </td>
                  <td width="70%">
                    <TimeComboBox
                      :time-rules="[requiredRule]"
                      :time="inputTimeFormDayTime_Time"
                      @update:time="inputTimeFormDayTime_Time = $event"
                      @time-isValid="timeIsValid"
                    />
                  </td>
                </tr>
              </table>
            </template>
            <template v-if="timesMode === 'time'">
              <table>
                <tr>
                  <td>
                    <TimeComboBox
                      :time-rules="[requiredRule]"
                      :time="inputTimeFormTime_Time"
                      @update:time="inputTimeFormTime_Time = $event"
                      @time-isValid="timeIsValid"
                    />
                  </td>
                </tr>
              </table>
            </template>
          </v-form>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="timeFormCancel">{{
            $t("__cancel")
          }}</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="timeFormOk"
            :disabled="!timeComboBoxIsValid"
            >{{ $t("__confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import format from "date-fns/format";
import TimeComboBox from "@/components/StoreMessageCronJobs/TimeComboBox.vue";

export default {
  name: "TimesPicker",
  components: {
    TimeComboBox
  },
  props: {
    "times-mode": {
      type: String
    },
    times: {
      type: Array
    },
    "start-date": {
      type: String
    },
    "start-time": {
      type: String
    },
    "end-date": {
      type: String
    },
    "end-time": {
      type: String
    }
  },
  computed: {
    inputTimesMode: {
      get() {
        return this.timesMode;
      },
      set(val) {
        this.inputTimes = [];
        this.$emit("update:times-mode", val);
      }
    },
    inputTimes: {
      get() {
        const times = [];
        switch (this.timesMode) {
          case "date-time":
            for (const time of this.times) {
              times.push(this.formatTime(time));
            }
            break;
          case "weekday-time":
            for (const time of this.times) {
              const timeKeys = time.split(",");
              times.push(
                `${this.$t(`__weekday_${timeKeys[0]}`)} ${timeKeys[1].padStart(
                  2,
                  "0"
                )}:${timeKeys[2].padStart(2, "0")}`
              );
            }
            break;
          case "day-time":
            for (const time of this.times) {
              const timeKeys = time.split(",");
              times.push(
                `(${timeKeys[0]}) ${timeKeys[1].padStart(
                  2,
                  "0"
                )}:${timeKeys[2].padStart(2, "0")}`
              );
            }
            break;
          case "time":
            for (const time of this.times) {
              const timeKeys = time.split(",");
              times.push(
                `${timeKeys[0].padStart(2, "0")}:${timeKeys[1].padStart(
                  2,
                  "0"
                )}`
              );
            }
            break;
        }
        return times;
      },
      set(val) {
        this.$emit("update:times", val);
      }
    },
    inputTimeFormDateTime_Date: {
      get() {
        return this.timeFormValue
          ? this.date2DateInputStr(new Date(this.timeFormValue))
          : "";
      },
      set(val) {
        this.timeFormValue = this.timeInputStr2TimeStr(
          val,
          this.inputTimeFormDateTime_Time
        );
      }
    },
    inputTimeFormDateTime_Time: {
      get() {
        return this.timeFormValue
          ? this.date2TimeInputStr(new Date(this.timeFormValue))
          : "";
      },
      set(val) {
        let hour = Number(val.split(":")[0]);
        let minute = Number(val.split(":")[1]);
        if (hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
          this.timeFormValue = this.timeInputStr2TimeStr(
            this.inputTimeFormDateTime_Date,
            val
          );
        }
      }
    },
    inputTimeFormDayTime_Day: {
      get() {
        if (this.timeFormValue) {
          const values = this.timeFormValue.split(",");
          if (values.length == 4) return values[0];
        }
        return "1";
      },
      set(val) {
        this.timeFormValue = this.dayTimeValues2TimesTime(
          val,
          this.inputTimeFormDayTime_Time
        );
      }
    },
    inputTimeFormDayTime_Time: {
      get() {
        if (this.timeFormValue) {
          const values = this.timeFormValue.split(",");
          if (values.length == 4)
            return `${values[1].padStart(2, "0")}:${values[2].padStart(
              2,
              "0"
            )}`;
        }
        return "1";
      },
      set(val) {
        console.log(val);
        this.timeFormValue = this.dayTimeValues2TimesTime(
          this.inputTimeFormDayTime_Day,
          val
        );
      }
    },
    inputTimeFormTime_Time: {
      get() {
        if (this.timeFormValue) {
          const values = this.timeFormValue.split(",");
          if (values.length == 3)
            return `${values[0].padStart(2, "0")}:${values[1].padStart(
              2,
              "0"
            )}`;
        }
        return "1";
      },
      set(val) {
        this.timeFormValue = this.time2TimesTime(val);
      }
    }
  },
  data() {
    return {
      timesModes: [
        {
          text: this.$t("__storemessagecronjob_timesmode_date-time"),
          value: "date-time"
        },
        {
          text: this.$t("__storemessagecronjob_timesmode_weekday-time"),
          value: "weekday-time"
        },
        {
          text: this.$t("__storemessagecronjob_timesmode_day-time"),
          value: "day-time"
        },
        {
          text: this.$t("__storemessagecronjob_timesmode_time"),
          value: "time"
        }
      ],
      weekdays: [
        {
          text: this.$t("__weekday_0"),
          value: "0"
        },
        {
          text: this.$t("__weekday_1"),
          value: "1"
        },
        {
          text: this.$t("__weekday_2"),
          value: "2"
        },
        {
          text: this.$t("__weekday_3"),
          value: "3"
        },
        {
          text: this.$t("__weekday_4"),
          value: "4"
        },
        {
          text: this.$t("__weekday_5"),
          value: "5"
        },
        {
          text: this.$t("__weekday_6"),
          value: "6"
        }
      ],
      timeFormShow: false,
      timeFormIndex: -1,
      timeFormValue: "",
      timeFormIsValid: true,
      timeComboBoxIsValid: false,
      requiredRule: v => !!v || this.$t("__required"),
      timesRequiredRule: () => this.times.length > 0 || this.$t("__required"),
      dayRule: v =>
        !v ||
        (!isNaN(v) && parseInt(v) >= 1 && parseInt(v) <= 31) ||
        this.$t("__invalid"),
      dayTimeTimePickerMenuShow: false,
      timeTimePickerMenuShow: false
    };
  },
  methods: {
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd HH:mm");
    },
    date2DateInputStr(date) {
      if (date !== "Invalid Date") {
        return format(date, "yyyy-MM-dd");
      }
    },
    date2TimeInputStr(date) {
      if (date !== "Invalid Date") {
        return format(date, "HH:mm");
      }
    },
    timeInputStr2TimeStr(dateInputStr, timeInputStr) {
      return dateInputStr + "T" + timeInputStr + ":00+08:00";
    },
    getNextHoursDate(date = new Date(), hours = 1) {
      date.setHours(date.getHours() + hours);
      date.setMinutes(0);
      return date;
    },
    dayTimeValues2TimesTime(day, time) {
      return `${day},${this.time2TimesTime(time)}`;
    },
    time2TimesTime(time) {
      const tvals = time.split(":");
      return `${tvals[0]},${tvals[1]},28800`;
    },
    addTime() {
      let time = "";
      switch (this.timesMode) {
        case "date-time":
          {
            let d = this.startDate;
            if (!d)
              d = this.date2DateInputStr(new Date(this.getNextHoursDate()));
            let t = this.startTime;
            if (!t)
              t = this.date2TimeInputStr(new Date(this.getNextHoursDate()));
            time = this.timeInputStr2TimeStr(d, t);
          }
          break;
        case "weekday-time":
        case "day-time":
          time = "1,10,00,28800";
          break;
        case "time":
          time = "10,00,28800";
          break;
      }
      this.showTimeForm(-1, time);
    },
    setTime(index) {
      this.showTimeForm(index, this.times[index]);
    },
    deleteTime(index) {
      const times = this.times;
      this.$delete(times, index);
      this.inputTimes = times;
    },
    showTimeForm(index, time) {
      this.timeFormIndex = index;
      this.timeFormValue = time;
      this.$refs.timeForm?.resetValidation();
      this.timeFormShow = true;
    },
    closeTimeForm() {
      this.timeFormIndex = -1;
      this.timeFormValue = "";
      this.timeFormShow = false;
    },
    timeFormOk() {
      this.$refs.timeForm?.validate();
      if (this.timeFormIsValid) {
        const times = this.times;
        if (this.timeFormIndex >= 0) {
          this.$set(times, this.timeFormIndex, this.timeFormValue);
        } else {
          times.splice(times.length, 1, this.timeFormValue);
        }
        this.inputTimes = times;
        this.closeTimeForm();
      }
    },
    timeFormCancel() {
      this.closeTimeForm();
    },
    onTimesUpdateError(val) {
      console.log("onTimesUpdateError", val);
    },
    timeIsValid(val) {
      this.timeComboBoxIsValid = val;
    }
  }
};
</script>

<style lang="scss">
.times-table {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
</style>
