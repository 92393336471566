<template>
  <div class="store-message-cron-jobs">
    <v-container fluid>
      <v-row align="center" justify="center" dense>
        <v-col cols="12" sm="12" lg="12">
          <v-card outlined>
            <alert
              class="alert-style"
              :title="$t('__storemessagecronjob_messages_limited')"
              :color-type="'error'"
              :alert-open="messageLimited"
              :dismissible="false"
            />
            <v-alert
              border="left"
              colored-border
              :type="messageAlert"
              elevation="1"
              v-if="storeMessageInterval"
            >
              <p class="mb-0">
                {{
                  $t(`__${storeMessageInterval}`) +
                    " " +
                    $t("__storemessagecronjob_histories")
                }}
                <span class="font-weight-bold">{{ countMessage }}</span>
                {{ $t("__storemessagecronjob_messages") }}
              </p>
              <template v-if="storeMessageQuantity !== -1">
                <p class="mb-0">
                  {{ $t("__storemessagecronjob_limit") }}
                  <span class="font-weight-bold">{{ limitMessage }}</span>
                  {{ $t("__storemessagecronjob_messages") }}
                </p>
              </template>
              <template v-else>
                <p class="mb-0">{{ limitMessage }}</p>
              </template>
            </v-alert>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{
                $t("__storemessagecronjobs")
              }}</v-toolbar-title>
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined @click="toAdd()" v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("__new") }}</span>
              </v-tooltip>
            </v-toolbar>
            <v-data-table :headers="headers" :items="items">
              <template v-slot:[`item.type`]="{ item }">
                <template v-if="item.type.href">
                  <a :href="item.type.href" target="_blank">{{
                    item.type.name
                  }}</a>
                </template>
                <div v-else>{{ item.type.name }}</div>
                <v-chip v-for="(data, i) of item.type.datas" :key="i" x-small>
                  {{ data }}
                </v-chip>
              </template>
              <template v-slot:[`item.msg`]="{ item }">
                <div class="py-2">
                  <v-card outlined>
                    <div class="multiline pa-2">{{ item.msg }}</div>
                  </v-card>
                </div>
              </template>
              <template v-slot:[`item.times`]="{ item }">
                <div class="py-2">
                  <table class="data-table">
                    <tr>
                      <th>
                        {{ $t("__storemessagecronjobs_list_times_mode") }}
                      </th>
                      <td>{{ item.timesMode }}</td>
                    </tr>
                    <tr>
                      <th :rowspan="item.times.length + 1">
                        {{ $t("__storemessagecronjobs_list_times_times") }}
                      </th>
                      <td v-if="item.times.length === 0" />
                    </tr>
                    <tr v-for="time of item.times" :key="time">
                      <td>{{ time }}</td>
                    </tr>
                  </table>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="py-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="primary"
                        @click="toSet(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("__edit") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="primary"
                        @click="toExecute(item.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-message-arrow-right</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("__storemessagecronjob_send_now") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-2"
                        icon
                        small
                        color="red"
                        @click="toDelete(item.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("__delete") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="storePickerDialogShow" persistent max-width="600px">
      <v-card>
        <v-card-title v-text="$t('__storemessagecronjobs_select_store')" />
        <div class="pa-4 pb-0">
          <StorePicker
            :store-ids="storeIDs"
            :store-datas="storeDatas"
            v-model="storePickerStoreID"
            @ok="onFormOk"
            @cancel="onFormCancel"
          />
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onStorePickerDialogCancel"
            >{{ $t("__cancel") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="onStorePickerDialogOk">{{
            $t("__confirm")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="formShow" persistent max-width="600px">
      <StoreMessageCronJobForm
        :store-id="formStoreID"
        :data="formStoreMessageCronJobData"
        @ok="onFormOk"
        @cancel="onFormCancel"
      />
    </v-dialog>
    <AlertDialog
      :show="alertDialogShow"
      :title="alertDialogTitle"
      :text="alertDialogText"
      :oktext="$t('__confirm')"
      :canceltext="$t('__cancel')"
      @ok="onAlertDialogOk"
      @cancel="onAlertDialogCancel"
    />
  </div>
</template>

<script>
import StorePicker from "@/components/StoreMessageCronJobs/StorePicker";
import StoreMessageCronJobForm from "@/components/StoreMessageCronJobs/StoreMessageCronJobForm";
import AlertDialog from "@/components/AlertDialog";

import { mapGetters } from "vuex";
import format from "date-fns/format";

export default {
  name: "StoreMessageCronJobs",
  components: {
    StorePicker,
    StoreMessageCronJobForm,
    AlertDialog
  },
  computed: {
    ...mapGetters({
      userID: "user/uid",
      storeIDs: "stores/ids",
      storeDatas: "stores/datas",
      smcjDatas: "moduleStoreMessageCronJob/storeMessageCronJobDatas",
      storeMessageQuantity: "moduleStoreMessageCronJob/storeMessageQuantity",
      storeMessageInterval: "moduleStoreMessageCronJob/storeMessageInterval",
      storeMessageCounts: "moduleStoreMessageCronJob/storeMessageCounts"
    }),
    countMessage() {
      return this.storeMessageCounts[this.storeMessageInterval];
    },
    limitMessage() {
      let quantity = "";
      if (this.storeMessageQuantity === -1) {
        quantity = this.$t("__storemessagecronjob_messages_unlimited");
      } else {
        quantity = this.storeMessageQuantity;
      }
      return quantity;
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("__storemessagecronjobs_list_store"),
          value: "store",
          align: "start"
        },
        {
          text: this.$t("__storemessagecronjobs_list_name"),
          value: "name",
          align: "start",
          width: "10%"
        },
        {
          text: this.$t("__storemessagecronjobs_list_type"),
          value: "type",
          align: "start",
          width: "5%"
        },
        {
          text: this.$t("__storemessagecronjobs_list_msg"),
          value: "msg",
          align: "start",
          sortable: false
        },
        {
          text: this.$t("__storemessagecronjobs_list_time"),
          value: "time",
          align: "start"
        },
        {
          text: this.$t("__storemessagecronjobs_list_times"),
          value: "times",
          align: "start",
          sortable: false
        },
        {
          text: this.$t("__storemessagecronjobs_list_lastexecutetime"),
          value: "lastExecuteTime",
          align: "start",
          width: "15%"
        },
        {
          text: this.$t("__storemessagecronjobs_list_actions"),
          value: "actions",
          align: "end",
          sortable: false,
          width: "15%"
        }
      ],
      items: [],
      storePickerDialogShow: false,
      storePickerStoreID: "",
      formShow: false,
      formStoreID: "",
      formStoreMessageCronJobID: "",
      formStoreMessageCronJobData: null,
      alertDialogShow: false,
      alertDialogTitle: "",
      alertDialogText: "",
      alertDialogAction: null,
      alertDialogData: null,
      messageAlert: "info",
      messageLimited: false
    };
  },
  methods: {
    update() {
      this.items = [];
      for (const data of this.smcjDatas) {
        const smcjData = data.data;

        // store
        const storeID = smcjData["store_id"];
        const storeName = this.storeDatas[storeID].name;

        // type
        let type = {
          name: this.$t("__storemessagecronjob_key_text"),
          datas: []
        };
        switch (smcjData["key"]) {
          case "url":
            type.name = this.$t("__storemessagecronjob_key_url");
            type.href = smcjData["data"]["url"];
            break;
          case "item":
            type.name = this.$t("__storemessagecronjob_key_item");
            for (const item of smcjData["data"]["items"]) {
              const itemName = item.name;
              const itemID = item.id;
              type.datas.push(itemName ? itemName : itemID);
            }
            break;
          case "category":
            type.name = this.$t("__storemessagecronjob_key_category");
            for (const category of smcjData["data"]["categories"]) {
              const categoryName = category.name;
              const categoryID = category.id;
              type.datas.push(categoryName ? categoryName : categoryID);
            }
            break;
        }

        // msg
        const msg =
          (smcjData["title"] ? smcjData["title"] + "\n\n" : "") +
          smcjData["text"];

        // time
        const time = `${this.formatTime2Day(
          smcjData["start_time"]
        )} ~ ${this.formatTime2Day(smcjData["end_time"])}`;

        // times
        const timesMode = this.$t(
          `__storemessagecronjob_timesmode_${smcjData["times_mode"]}`
        );
        let times = [];
        switch (smcjData["times_mode"]) {
          case "date-time":
            for (const time of smcjData["times"]) {
              times.push(this.formatTime(time));
            }
            break;
          case "weekday-time":
            for (const time of smcjData["times"]) {
              const timeKeys = time.split(",");
              times.push(
                `${this.$t(
                  `__weekday_${timeKeys[0]}_short`
                )} ${timeKeys[1].padStart(2, "0")}:${timeKeys[2].padStart(
                  2,
                  "0"
                )}`
              );
            }
            break;
          case "day-time":
            for (const time of smcjData["times"]) {
              const timeKeys = time.split(",");
              times.push(
                `(${timeKeys[0]}) ${timeKeys[1].padStart(
                  2,
                  "0"
                )}:${timeKeys[2].padStart(2, "0")}`
              );
            }
            break;
          case "time":
            for (const time of smcjData["times"]) {
              const timeKeys = time.split(",");
              times.push(
                `${timeKeys[0].padStart(2, "0")}:${timeKeys[1].padStart(
                  2,
                  "0"
                )}`
              );
            }
            break;
        }

        // last execute time
        const lastExecuteTime = this.formatTime(smcjData["last_execute_time"]);

        this.items.push({
          store: storeName,
          id: data.id,
          name: smcjData["name"],
          type,
          msg,
          time,
          timesMode,
          times,
          lastExecuteTime,
          title: smcjData["title"],
          text: smcjData["text"]
        });
      }
    },
    loadUserStores() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("stores/getUserStores", { userID: this.userID })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error("load stores error: ", err);
        });
    },
    loadUserStoreMessageCronJobs() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("moduleStoreMessageCronJob/fetchStoreMessageCronJob", {
          userID: this.userID
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error("load jobs error: ", err);
        });
    },
    loadUserLimits() {
      return this.$store
        .dispatch("moduleStoreMessageCronJob/getUserLimits", {
          userID: this.userID
        })
        .catch(err => {
          console.error("get user limits error: ", err);
        });
    },
    addStoreMessageCronJob(
      storeID,
      {
        name,
        key,
        data,
        title,
        text,
        mediaType,
        mediaUrl,
        startTime,
        endTime,
        timesMode,
        times
      }
    ) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("moduleStoreMessageCronJob/addStoreMessageCronJob", {
          storeID,
          name,
          key,
          data,
          title,
          text,
          mediaType,
          mediaUrl,
          startTime,
          endTime,
          timesMode,
          times
        })
        .then(() => {
          this.loadUserStoreMessageCronJobs();
        })
        .catch(err => {
          console.error("add job error: ", err);
        });
    },
    setStoreMessageCronJob(
      storeMessageCronJobID,
      {
        name,
        key,
        data,
        title,
        text,
        mediaType,
        mediaUrl,
        startTime,
        endTime,
        timesMode,
        times
      }
    ) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("moduleStoreMessageCronJob/setStoreMessageCronJob", {
          storeMessageCronJobID,
          setData: {
            name: name,
            key: key,
            data: data,
            title: title,
            text: text,
            media_type: mediaType,
            media_url: mediaUrl,
            start_time: startTime,
            end_time: endTime,
            times_mode: timesMode,
            times: times
          }
        })
        .then(() => {
          this.loadUserStoreMessageCronJobs();
        })
        .catch(err => {
          console.error("set job error: ", err);
        });
    },
    deleteStoreMessageCronJob(storeMessageCronJobID) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("moduleStoreMessageCronJob/deleteStoreMessageCronJob", {
          storeMessageCronJobID
        })
        .then(() => {
          this.loadUserStoreMessageCronJobs();
        })
        .catch(err => {
          console.error("delete job error: ", err);
        });
    },
    executeStoreMessageCronJob(storeMessageCronJobID) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("moduleStoreMessageCronJob/executeStoreMessageCronJob", {
          storeMessageCronJobID
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
          this.getStoreMessageCronJobExecutionCount();
        })
        .catch(err => {
          console.error("execute job error: ", err);
        });
    },
    toAdd() {
      if (this.storeIDs.length > 1) {
        this.showStorePickerDialog();
      } else {
        this.showFrom(this.storeIDs[0]);
      }
    },
    toSet(item) {
      const data = this.smcjDatas.filter(smcj => {
        return smcj.id === item.id;
      });
      this.showFrom(data[0]);
    },
    toDelete(id) {
      this.showAlertDialog(
        this.$t("__storemessagecronjobs_delete_title"),
        this.$t("__storemessagecronjobs_delete_msg"),
        this.deleteStoreMessageCronJob,
        id
      );
    },
    toExecute(id) {
      if (
        this.storeMessageQuantity !== -1 &&
        this.storeMessageQuantity === this.countMessage
      ) {
        this.messageLimited = true;
      } else {
        this.showAlertDialog(
          this.$t("__storemessagecronjobs_execute_title"),
          this.$t("__storemessagecronjobs_execute_msg"),
          this.executeStoreMessageCronJob,
          id
        );
      }
    },
    showStorePickerDialog() {
      this.storePickerStoreID = this.storeIDs[0];
      this.storePickerDialogShow = true;
    },
    closeStorePickerDialog() {
      this.storePickerDialogShow = false;
    },
    onStorePickerDialogOk() {
      const storeID = this.storePickerStoreID;
      this.closeStorePickerDialog();
      this.showFrom(storeID);
    },
    onStorePickerDialogCancel() {
      this.closeStorePickerDialog();
    },
    showFrom(data) {
      if (typeof data !== "string") {
        this.formStoreID = data.data["store_id"];
        this.formStoreMessageCronJobID = data.id;
        this.formStoreMessageCronJobData = data.data;
      } else {
        this.formStoreID = data;
      }
      this.formShow = true;
    },
    closeFrom() {
      this.formStoreID = "";
      this.formStoreMessageCronJobID = "";
      this.formStoreMessageCronJobData = null;
      this.formShow = false;
    },
    onFormOk(form) {
      if (this.formStoreMessageCronJobID) {
        // set
        this.setStoreMessageCronJob(this.formStoreMessageCronJobID, form);
      } else {
        // add
        this.addStoreMessageCronJob(this.storePickerStoreID, form);
      }
      this.closeFrom();
    },
    onFormCancel() {
      this.closeFrom();
    },
    showAlertDialog(title, text, action, data) {
      this.alertDialogTitle = title;
      this.alertDialogText = text;
      this.alertDialogAction = action;
      this.alertDialogData = data;
      this.alertDialogShow = true;
    },
    closeAlertDialog() {
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogAction = null;
      this.alertDialogData = null;
      this.alertDialogShow = false;
    },
    onAlertDialogOk() {
      if (this.alertDialogAction) {
        this.alertDialogAction(this.alertDialogData);
      }
      this.closeAlertDialog();
    },
    onAlertDialogCancel() {
      this.closeAlertDialog();
    },
    formatTime2Day(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd");
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd HH:mm");
    },
    getStoreMessageCronJobExecutionCount() {
      return this.$store.dispatch(
        "moduleStoreMessageCronJob/getStoreMessageCronJobExecutionCount",
        { userID: this.userID }
      );
    }
  },
  mounted() {
    this.loadUserStores().then(() => {
      return this.loadUserStoreMessageCronJobs();
    });
  },
  created() {
    this.getStoreMessageCronJobExecutionCount();
    this.loadUserLimits();
    this.messageAlert = "info";
  },
  watch: {
    smcjDatas: {
      deep: true,
      handler() {
        this.update();
      }
    },
    countMessage: {
      immediate: true,
      handler(val) {
        if (
          this.storeMessageQuantity !== -1 &&
          this.storeMessageQuantity === val
        ) {
          this.messageAlert = "error";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.multiline {
  white-space: pre-wrap;
}
.data-table {
  border-collapse: collapse;

  th,
  td {
    border: 1px solid silver;
    padding: 2px 8px;
  }
  th {
    padding-right: 8px;
    text-align: left;
    vertical-align: top;
  }
}
.v-alert.alert-style {
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 100;
}
</style>
