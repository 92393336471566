<template>
  <v-form v-model="isValid">
    <td>
      <v-select
        outlined
        dense
        :label="$t('__storemessagecronjobs_times_period')"
        :items="timePeriodOptions"
        item-text="ch"
        item-value="en"
        v-model="timePeriodSelect"
      />
    </td>
    <td>
      <v-combobox
        outlined
        dense
        :label="$t('__storemessagecronjobs_times_hour')"
        :items="hourOptions"
        :rules="[...timeRules, hourRule]"
        v-model="hour"
      />
    </td>
    <td>
      <v-combobox
        outlined
        dense
        :label="$t('__storemessagecronjobs_times_minute')"
        :items="minuteOptions"
        :rules="[...timeRules, minuteRule]"
        v-model="minute"
      />
    </td>
  </v-form>
</template>
<script>
export default {
  name: "",
  computed: {
    outputTime() {
      return `${this.hour}:${this.minute}`;
    }
  },
  props: {
    time: {
      type: String
    },
    "time-rules": {
      type: Array
    }
  },
  data() {
    return {
      hourRule: v => this.hourOptions.includes(v) || this.$t("__time_invalid"),
      minuteRule: v =>
        this.minuteOptions.includes(v) || this.$t("__time_invalid"),
      timePeriodOptions: [
        {
          ch: this.$t("__storemessagecronjobs_times_morning"),
          en: "morning"
        },
        {
          ch: this.$t("__storemessagecronjobs_times_afternoon"),
          en: "afternoon"
        }
      ],
      timePeriodSelect: "",
      minuteOptions: [
        "00",
        "05",
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55"
      ],
      hourOptions: [],
      hour: this.time.split(":")[0],
      minute: this.time.split(":")[1],
      isValid: false
    };
  },
  watch: {
    time: {
      immediate: true,
      handler(val) {
        if (val !== 1) {
          this.hour = val.split(":")[0];
          this.minute = val.split(":")[1];
        }
      }
    },
    outputTime: {
      handler(val) {
        if (this.hour && this.minute && this.isValid) {
          this.$emit("update:time", val);
        }
      }
    },
    hour: {
      immediate: true,
      handler(val) {
        if (val && Number(val) <= 12) {
          this.timePeriodSelect = "morning";
        } else if (val && Number(val) > 12) {
          this.timePeriodSelect = "afternoon";
        }
      }
    },
    timePeriodSelect: {
      immediate: true,
      handler(val) {
        if (val === "morning") {
          this.hourOptions = [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12"
          ];
        } else {
          this.hourOptions = [
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23"
          ];
        }
        this.hour = this.hourOptions[0];
      }
    },
    isValid: {
      immediate: true,
      handler(val) {
        this.$emit("time-isValid", val);
      }
    }
  }
};
</script>
