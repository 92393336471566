var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-message-cron-jobs"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('alert',{staticClass:"alert-style",attrs:{"title":_vm.$t('__storemessagecronjob_messages_limited'),"color-type":'error',"alert-open":_vm.messageLimited,"dismissible":false}}),(_vm.storeMessageInterval)?_c('v-alert',{attrs:{"border":"left","colored-border":"","type":_vm.messageAlert,"elevation":"1"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t(("__" + _vm.storeMessageInterval)) + " " + _vm.$t("__storemessagecronjob_histories"))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.countMessage))]),_vm._v(" "+_vm._s(_vm.$t("__storemessagecronjob_messages"))+" ")]),(_vm.storeMessageQuantity !== -1)?[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("__storemessagecronjob_limit"))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.limitMessage))]),_vm._v(" "+_vm._s(_vm.$t("__storemessagecronjob_messages"))+" ")])]:[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.limitMessage))])]],2):_vm._e(),_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("__storemessagecronjobs")))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.toAdd()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("__new")))])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type.href)?[_c('a',{attrs:{"href":item.type.href,"target":"_blank"}},[_vm._v(_vm._s(item.type.name))])]:_c('div',[_vm._v(_vm._s(item.type.name))]),_vm._l((item.type.datas),function(data,i){return _c('v-chip',{key:i,attrs:{"x-small":""}},[_vm._v(" "+_vm._s(data)+" ")])})]}},{key:"item.msg",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('v-card',{attrs:{"outlined":""}},[_c('div',{staticClass:"multiline pa-2"},[_vm._v(_vm._s(item.msg))])])],1)]}},{key:"item.times",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('table',{staticClass:"data-table"},[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t("__storemessagecronjobs_list_times_mode"))+" ")]),_c('td',[_vm._v(_vm._s(item.timesMode))])]),_c('tr',[_c('th',{attrs:{"rowspan":item.times.length + 1}},[_vm._v(" "+_vm._s(_vm.$t("__storemessagecronjobs_list_times_times"))+" ")]),(item.times.length === 0)?_c('td'):_vm._e()]),_vm._l((item.times),function(time){return _c('tr',{key:time},[_c('td',[_vm._v(_vm._s(time))])])})],2)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.toSet(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.toExecute(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__storemessagecronjob_send_now")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.toDelete(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__delete")))])])],1)]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.storePickerDialogShow),callback:function ($$v) {_vm.storePickerDialogShow=$$v},expression:"storePickerDialogShow"}},[_c('v-card',[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.$t('__storemessagecronjobs_select_store'))}}),_c('div',{staticClass:"pa-4 pb-0"},[_c('StorePicker',{attrs:{"store-ids":_vm.storeIDs,"store-datas":_vm.storeDatas},on:{"ok":_vm.onFormOk,"cancel":_vm.onFormCancel},model:{value:(_vm.storePickerStoreID),callback:function ($$v) {_vm.storePickerStoreID=$$v},expression:"storePickerStoreID"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onStorePickerDialogCancel}},[_vm._v(_vm._s(_vm.$t("__cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onStorePickerDialogOk}},[_vm._v(_vm._s(_vm.$t("__confirm")))])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.formShow),callback:function ($$v) {_vm.formShow=$$v},expression:"formShow"}},[_c('StoreMessageCronJobForm',{attrs:{"store-id":_vm.formStoreID,"data":_vm.formStoreMessageCronJobData},on:{"ok":_vm.onFormOk,"cancel":_vm.onFormCancel}})],1),_c('AlertDialog',{attrs:{"show":_vm.alertDialogShow,"title":_vm.alertDialogTitle,"text":_vm.alertDialogText,"oktext":_vm.$t('__confirm'),"canceltext":_vm.$t('__cancel')},on:{"ok":_vm.onAlertDialogOk,"cancel":_vm.onAlertDialogCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }