<template>
  <div class="store-message-cron-job-form">
    <v-card>
      <v-card-title>
        <span>{{ $t("__storemessagecronjob") }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <!-- name -->
          <v-text-field
            outlined
            :label="$t('__storemessagecronjob_name') + ' *'"
            v-model="form.name"
            type="text"
            :rules="[requiredRule]"
            dense
          />

          <v-divider class="mb-6" />

          <!-- message -->
          <div class="text-h6 mb-2">{{ $t("__storemessagecronjob_msg") }}</div>
          <v-text-field
            outlined
            :label="$t('__storemessagecronjob_title')"
            v-model="form.title"
            type="text"
            dense
          />
          <v-textarea
            outlined
            :label="$t('__storemessagecronjob_text') + ' *'"
            v-model="form.text"
            :rules="[requiredRule]"
            dense
          />

          <!-- media type -->
          <div class="d-flex align-baseline">
            <v-checkbox
              class="mt-0 pr-3"
              v-model="form.mediaType"
              true-value="photo"
              false-value="none"
              :label="$t('__storemessagecronjob_media_photo')"
            >
            </v-checkbox>
            <!-- media url -->
            <photo-url-input
              class="flex-grow-1"
              v-if="form.mediaType !== 'none'"
              dense
              outlined
              v-model="form.mediaUrl"
              :label="$t('__storemessagecronjob_data_url')"
            />
          </div>

          <!-- key -->
          <v-select
            v-model="form.key"
            :items="keys"
            :label="$t('__storemessagecronjob_key')"
            outlined
            dense
          />
          <!-- data url -->
          <v-text-field
            v-if="form.key === 'url'"
            outlined
            :label="$t('__storemessagecronjob_data_url')"
            v-model="form.data.url"
            type="url"
            :rules="[requiredRule, urlRule]"
            dense
          />
          <!-- data item and category -->
          <div v-if="form.key === 'item'" class="text-h7 mb-2">
            {{ $t("__storemessagecronjob_data_item") }}
          </div>
          <div v-if="form.key === 'category'" class="text-h7 mb-2">
            {{ $t("__storemessagecronjob_data_category") }}
          </div>
          <v-input
            v-if="form.key === 'item' || form.key === 'category'"
            :messages="itemCategoryCountMsg"
            :error="itemCategoryCount > 10"
            class="mb-4"
          >
            <div class="outline-div">
              <!-- data item -->
              <template v-if="form.key === 'item'">
                <template v-for="(_, i) of form.data.items">
                  <div :key="`item-${i}`" class="d-flex">
                    <v-combobox
                      class="ma-2 mb-0"
                      outlined
                      v-model="form.data.items[i]"
                      :items="itemList"
                      :rules="[requiredRule, itemCategoryRule]"
                      dense
                      :loading="loadingStore"
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.text ? item.text : "" }}
                      </template>
                    </v-combobox>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          icon
                          @click="deleteItemOrCategory(i)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon large color="grey lighten-1"
                            >mdi-minus-circle-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("__delete") }}</span>
                    </v-tooltip>
                  </div>
                  <v-divider :key="`item-${i}-divider`" />
                </template>
              </template>
              <!-- data category -->
              <template v-if="form.key === 'category'">
                <template v-for="(_, i) of form.data.categories">
                  <div :key="`category-${i}`" class="d-flex">
                    <v-combobox
                      v-if="form.key === 'category'"
                      class="ma-2 mb-0"
                      outlined
                      v-model="form.data.categories[i]"
                      :items="categoryList"
                      :rules="[requiredRule, itemCategoryRule]"
                      dense
                      :loading="loadingStore"
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.text ? item.text : "" }}
                      </template>
                    </v-combobox>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          icon
                          @click="deleteItemOrCategory(i)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon large color="grey lighten-1"
                            >mdi-minus-circle-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("__delete") }}</span>
                    </v-tooltip>
                  </div>
                  <v-divider :key="`category-${i}-divider`" />
                </template>
              </template>
              <!-- add button -->
              <div class="d-flex justify-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2"
                      icon
                      @click="addItemOrCategory"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon large color="grey lighten-1"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{ $t("__new") }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-input>

          <v-divider class="mb-6" />
          <!-- times -->
          <div class="text-h6 mb-2">
            {{ $t("__storemessagecronjob_times") }}
            <span class="error--text"> *</span>
          </div>
          <TimesPicker
            :times-mode="form.timesMode"
            @update:times-mode="form.timesMode = $event"
            :times="form.times"
            @update:times="form.times = $event"
            :start-date="form.startTime.date"
            :start-time="form.startTime.time"
            :end-date="form.endTime.date"
            :end-time="form.endTime.time"
          />
          <v-divider class="mb-6" />

          <!-- start time -->
          <div class="text-h6">
            {{ $t("__storemessagecronjob_starttime") }}
            <span class="error--text"> *</span>
          </div>
          <div class="caption mb-2">
            {{ $t("__storemessagecronjob_time_hint") }}
          </div>
          <date-time-picker
            :date="form.startTime.date"
            @update:date="form.startTime.date = $event"
            :time="form.startTime.time"
            @update:time="form.startTime.time = $event"
            :date-rules="[requiredRule]"
            :time-rules="[requiredRule]"
            :locale="$i18n.locale"
            :min-date="form.startTime.date"
            outlined
            dense
            @time-invalid="timeInvalid"
          />

          <!-- end time -->
          <div class="text-h6 mb-2">
            {{ $t("__storemessagecronjob_endtime") }}
          </div>
          <date-time-picker
            :date="form.endTime.date"
            @update:date="form.endTime.date = $event"
            :time="form.endTime.time"
            @update:time="form.endTime.time = $event"
            :date-rules="[requiredRule]"
            :time-rules="[requiredRule]"
            :min-date="form.startTime.date"
            :locale="$i18n.locale"
            outlined
            dense
            @time-invalid="timeInvalid"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancel">{{
          $t("__cancel")
        }}</v-btn>
        <v-btn color="blue darken-1" text @click="ok">{{
          $t("__confirm")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import TimesPicker from "@/components/StoreMessageCronJobs/TimesPicker";
import { mapGetters } from "vuex";
import { format, add } from "date-fns";

export default {
  name: "StoreMessageCronJobForm",
  components: {
    TimesPicker
  },
  props: {
    "store-id": {
      tyoe: String,
      required: true
    },
    data: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      loadedStoreID: "store/id",
      loadedStoreItems: "store/items",
      loadedStoreCategories: "store/categories"
    }),
    itemList() {
      const itemList = [];
      for (const item of this.loadedStoreItems) {
        itemList.push({
          id: item.id,
          text: item.data["name"]
        });
      }
      return itemList;
    },
    categoryList() {
      const categorieList = [];
      for (const category of this.loadedStoreCategories) {
        categorieList.push({
          id: category.id,
          text: category.data["name"]
        });
      }
      return categorieList;
    },
    itemCategoryCount() {
      if (this.form.key === "item") {
        return this.form.data.items.length;
      } else if (this.form.key === "category") {
        return this.form.data.categories.length;
      }
      return 0;
    },
    itemCategoryCountMsg() {
      return `${this.itemCategoryCount}/10`;
    }
  },
  data() {
    return {
      form: {
        name: "",
        key: "",
        data: {
          url: "",
          items: [],
          categories: []
        },
        title: "",
        text: "",
        mediaType: "",
        mediaUrl: "",
        startTime: {
          date: "",
          time: ""
        },
        endTime: {
          date: "",
          time: ""
        },
        timesMode: "",
        times: []
      },
      isValid: true,
      requiredRule: v => !!v || this.$t("__required"),
      urlRule: v => !v || this.isURL(v) || this.$t("__url_invalid"),
      itemCategoryRule: v =>
        (!!v && !!v.id && !!v.text) || this.$t("__required"),
      keys: [
        {
          text: this.$t("__storemessagecronjob_key_text"),
          value: "text"
        },
        {
          text: this.$t("__storemessagecronjob_key_url"),
          value: "url"
        },
        {
          text: this.$t("__storemessagecronjob_key_item"),
          value: "item"
        },
        {
          text: this.$t("__storemessagecronjob_key_category"),
          value: "category"
        }
      ],
      loadingStore: false
    };
  },
  methods: {
    getNextHoursDate(date = new Date(), hours = 0) {
      date.setHours(date.getHours() + hours);
      date.setMinutes(0);
      return date;
    },
    date2DateInputStr(date) {
      return format(date, "yyyy-MM-dd");
    },
    date2TimeInputStr(date) {
      return format(date, "HH:mm");
    },
    timeInputStr2TimeStr(dateInputStr, timeInputStr) {
      return dateInputStr + "T" + timeInputStr + ":00+08:00";
    },
    onStartTimeChange() {
      const startDate = new Date(
        this.timeInputStr2TimeStr(
          this.form.startTime.date,
          this.form.startTime.time
        )
      );
      const endDate = new Date(
        this.timeInputStr2TimeStr(
          this.form.endTime.date,
          this.form.endTime.time
        )
      );
      if (startDate >= endDate) {
        const newEndDate = this.getNextHoursDate(startDate, 2);
        this.form.endTime.date = this.date2DateInputStr(newEndDate);
        this.form.endTime.time = this.date2TimeInputStr(newEndDate);
      }
    },
    isURL(str) {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    addItemOrCategory() {
      if (this.form.key === "item") {
        if (this.form.data.items.length === 10) return;
        this.form.data.items.splice(this.form.data.items.length, 1, {});
      } else if (this.form.key === "category") {
        if (this.form.data.categories.length === 10) return;
        this.form.data.categories.splice(
          this.form.data.categories.length,
          1,
          {}
        );
      }
    },
    deleteItemOrCategory(index) {
      if (this.form.key === "item") {
        if (this.form.data.items.length === 1) return;
        this.form.data.items.splice(index, 1);
      } else if (this.form.key === "category") {
        if (this.form.data.categories.length === 1) return;
        this.form.data.categories.splice(index, 1);
      }
    },
    ok() {
      this.isValid = this.$refs.form.validate();
      if (this.isValid) {
        const data = {};
        switch (this.form.key) {
          case "url":
            data["url"] = this.form.data.url;
            break;
          case "item":
            data["items"] = [];
            for (const item of this.form.data.items) {
              data["items"].push({
                id: item.id,
                name: item.text
              });
            }
            break;
          case "category":
            data["categories"] = [];
            for (const category of this.form.data.categories) {
              data["categories"].push({
                id: category.id,
                name: category.text
              });
            }
            break;
        }
        if (this.form.mediaType === "none") {
          this.form.mediaUrl = "";
        }

        this.$emit("ok", {
          name: this.form.name,
          key: this.form.key,
          data,
          title: this.form.title,
          text: this.form.text,
          mediaType: this.form.mediaType,
          mediaUrl: this.form.mediaUrl,
          startTime: this.timeInputStr2TimeStr(
            this.form.startTime.date,
            this.form.startTime.time
          ),
          endTime: this.timeInputStr2TimeStr(
            this.form.endTime.date,
            this.form.endTime.time
          ),
          timesMode: this.form.timesMode,
          times: this.form.times
        });
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    timeInvalid(val) {
      this.isValid = val;
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$refs.form?.resetValidation();
        this.form.name =
          val && val["name"]
            ? val["name"]
            : this.$t("__storemessagecronjob_name_new");

        // key
        this.form.key = val && val["key"] ? val["key"] : "text";
        // media type
        this.form.mediaType =
          val && val["media_type"] ? val["media_type"] : "none";
        // media url
        this.form.mediaUrl =
          val && val["media_url"] ? val["media_url"] : "https://";
        // data url
        this.form.data.url =
          val && val["data"] && val["data"]["url"]
            ? val["data"]["url"]
            : "https://";
        // data item
        const dataItems =
          val && val["data"] && val["data"]["items"]
            ? val["data"]["items"]
            : [];
        this.form.data.items = [];
        for (const item of dataItems) {
          this.form.data.items.push({
            id: item.id,
            text: item.name
          });
        }
        // data category

        const dataCategories =
          val && val["data"] && val["data"]["categories"]
            ? val["data"]["categories"]
            : [];
        this.form.data.categories = [];
        for (const category of dataCategories) {
          this.form.data.categories.push({
            id: category.id,
            text: category.name
          });
        }
        this.form.title = val && val["title"] ? val["title"] : "";
        this.form.text = val && val["text"] ? val["text"] : "";

        const startDate =
          val && val["start_time"]
            ? new Date(val["start_time"])
            : this.getNextHoursDate();
        const endDate =
          val && val["end_time"]
            ? new Date(val["end_time"])
            : this.getNextHoursDate(add(new Date(), { days: 1 }));

        this.form.startTime.date = this.date2DateInputStr(startDate);
        this.form.startTime.time = this.date2TimeInputStr(startDate);
        this.form.endTime.date = this.date2DateInputStr(endDate);
        this.form.endTime.time = this.date2TimeInputStr(endDate);

        this.form.timesMode =
          val && val["times_mode"] ? val["times_mode"] : "date-time";
        if (val && val["times"]) {
          for (const i in val["times"]) {
            this.form.times.splice(i, 1, val["times"][i]);
          }
        } else {
          this.form.times = [];
        }

        this.$forceUpdate();
      }
    },
    "form.startTime": {
      immediate: true,
      deep: true,
      handler() {
        this.onStartTimeChange();
      }
    },
    "form.endTime": {
      immediate: true,
      deep: true,
      handler() {
        this.onStartTimeChange();
      }
    },
    "form.key": {
      immediate: true,
      handler(val) {
        if (val === "item" || val === "category") {
          // load store data
          if (this.storeId != this.loadedStoreID) {
            this.loadingStore = true;
            this.$store
              .dispatch("store/fetchStore", this.storeId)
              .then(() => {
                this.loadingStore = false;
              })
              .catch(err => {
                console.error("fetch store error:", err);
              });
          }
          // add item/category
          if (val === "item" && this.form.data.items.length == 0) {
            this.form.data.items.splice(0, 1, {});
          } else if (
            val === "category" &&
            this.form.data.categories.length == 0
          ) {
            this.form.data.categories.splice(0, 1, {});
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
.outline-div {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
</style>
