<template>
  <div class="store-picker">
    <v-select
      v-model="inputValue"
      :items="items"
      outlined/>
  </div>
</template>

<script>

export default {
  name: "StorePicker",
  props: {
    'store-ids': {
      type: Array
    },
    'store-datas': {
      type: Object
    },
    'value': {
      type: String
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    items() {
      const items = []
      for (const id of this.storeIds) {
        items.push({
          text: this.storeDatas[id]['name'],
          value: id
        })
      }
      return items
    }
  }
}
</script>
